import React from 'react';
import { Link } from 'react-router-dom';


function OMyBeer() {
    return (
        <div id='omybeer' className='page d-flex flex-column align-items-center rounded mx-2 mx-lg-auto p-5 col-lg-8'>
            <div className='d-flex flex-row gap-5'>
                <Link to={'/portfolio'} className='back'>
                    <i class="bi bi-arrow-left"></i>
                </Link>
                <h2 className="fs-3 mb-3">Présentation du projet "O'My Beer!"</h2>
            </div>
            <div className="separator mt-1"></div>
            <div className="mt-2">
                <div className="align-items-stretch mt-5">
                    <p className="">Il s'agit d'un projet de fin de formation réalisé de bout en bout sur quatre semaines (du maquettage aux tests), en équipe. Le rôle qui m'a été attribué pour cette réalisation était lead dev backend.</p>
                    <p className="">L'idée de l'application est de présenter des bières du monde entier, et de permettre aux utilisateurs du site de noter et donner leur avis sur ces bières. Ils peuvent également enregistrer des références dans une liste de favoris ou une liste "à tester".</p>
                    <p className=""><span className='text-decoration-underline'>Description rapide :</span> 
                        <ul>
                            <li>front-office accessible à tous, filtres et possibilité de recherche, et fonctionnalités accessibles sur connexion (gestion du compte utilisateur, gestion des listes "favoris" et "à tester", ajout de notes et de commentaires, ...)</li>
                            <li>back-office accessible aux administrateurs, avec CRUD bières / marques / catégories / utilisateurs</li>
                            <li>récupération d'images de bière via une API externe</li>
                        </ul>
                    </p>
                </div>
                <a className="project-link" href="https://www.omybeer.ovh/" title="Lien vers le site O'My Beer" target="_blank">-{'>'} Visiter le site {'<'}-</a>
                				
                <div id="carouselAutoplaying" className="carousel slide d-flex justify-content-center" data-bs-ride="carousel">
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselAutoplaying" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    </button>
                    <div className="carousel-inner mx-auto">
                        <div className="carousel-item active">
                            <img src="./img/omybeer1.png" alt="omybeer1" className="img-fluid carousel-image" />
                        </div>
                        <div className="carousel-item">
                            <img src="./img/omybeer2.png" className="d-block img-fluid carousel-image" alt="omybeer2"/>
                        </div>
                    </div>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselAutoplaying" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    </button>
                </div>
            </div>   
        </div>
    )
}

export default OMyBeer;