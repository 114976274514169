import React from 'react';
import { Link } from 'react-router-dom';
import './EnStock.css';

function EnStock() {
    return (
        <div id='enstock' className='page d-flex flex-column align-items-center rounded mx-2 mx-lg-auto p-5 col-lg-8'>
            <div className='d-flex flex-row gap-5'>
                <Link to={'/portfolio'} className='back'>
                    <i class="bi bi-arrow-left"></i>
                </Link>
                <h2 className="fs-3 mb-3">Présentation du projet "En stock!"</h2>
            </div>
            <div className="separator mt-1"></div>
            <div className="mt-2">
                <div className="align-items-stretch mt-5">
                    <p className="">Au cours de mes expériences professionnelles précédentes, j'ai pu me familiariser avec les problématiques de gestion de stock. Cela m'a inspiré la création du projet "En stock!" : une application que j'ai voulu la plus pragmatique et intuitive possible pour la gestion des quantités de stock. Elle est bien sûr perfectible et des fonctionnalités complémentaires pourraient être ajoutées, mais elle m'a permis de me frotter à un projet 100% de ma composition en Symfony/PHP/JavaScript.</p>
                    <p className=""><span className='text-decoration-underline'>Description rapide :</span> interface nécessitant une connexion, CRUD produits / marques / catégories / emplacements / utilisateurs (selon rôle), filtres pour faciliter les recherches, alertes personnalisées sur la page d'accueil, page "Entrées/ Sorties" pour une gestion plus fluide des mouvements de stock concernant plusieurs références.
                    </p>
                </div>
                <div className="video-container d-flex justify-content-center mt-2" >
                    <iframe src="https://www.youtube.com/embed/LvktpFEiGdo?si=xplYkXG0Sdy2Zv63?autoplay=1&rel=0&loop=1&sharing=0&showinfo=0" 
                    title="Vidéo de présentation"
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ border: "none" }}
                    ></iframe>
                </div>
            </div>   
        </div>
    )
}

export default EnStock;