import React from 'react';
import { Link } from "react-router-dom";
import './Portfolio.css';

function Portfolio() {
    return (
        <div id='portfolio' className='page d-flex flex-column align-items-center rounded mx-2 mx-lg-4 mx-xl-auto pt-5 p-2 p-lg-5 col-xl-8'>
            <h2 className="fs-3 mb-3">Portfolio</h2>
            <div className="separator2"></div>
            <div className="box d-flex justify-content-center flex-wrap mx-auto mt-5 p-2">
                
                <div className="project col-8 col-lg-7 col-xl-5" id="omybeer">
                    <Link to="/presentation-omybeer" className='text-decoration-none project-link' title="Lien vers la présentation d'O'My Beer!">
                    {/* <a className="text-decoration-none project-link" href="https://www.omybeer.ovh/" title="Lien vers le site O'My Beer" target="_blank"> */}
                        <div className="corner"></div>
                        <div className="box-title">O'My Beer!</div>
                        <div className="box-description">
                            <span>Site dédié à l'univers brassicole</span>
                            <p>Projet de fin de formation réalisé en équipe : découvrez et donnez votre avis sur des bières du monde entier!</p>
                            <p>Front-office, back-office, API externe et fixtures</p>
                            <p className="my-2"><i>PHP 8, Symfony 7, MySQL/MariaDB, JS Vanilla, HTML, CSS, SASS, Bootstrap</i></p>
                            <span className="fs-6">Cliquez pour découvrir</span>
                        </div>
                    {/* </a> */}
                    </Link>
                </div>

                <div className="project col-8 col-lg-7 col-xl-5" id="enstock">
                    <Link to="/presentation-enstock" className='text-decoration-none project-link' title="Lien vers la présentation d'En stock!">
                        <div className="corner"></div>
                        <div className="box-title">En stock!</div>
                        <div className="box-description">
                            <span>Gestionnaire de stock</span>
                            <p>Conçu pour une gestion optimale de stock, permet de gérer facilement les entrées et les sorties de produits, ainsi que les références, catégories, emplacements, etc.</p>
                            <p className="my-2"><i>PHP 8, Symfony 7, MySQL/MariaDB, JS Vanilla, HTML, CSS, Bootstrap</i></p>
                            <span className="fs-6">Cliquez pour découvrir</span>
                        </div>
                    </Link>
                </div>

                <div className="project col-8 col-lg-7 col-xl-5" id="trobot">
                    <a className="text-decoration-none project-link" href="https://www.mariondev.fr/trobot" title="Lien vers le projet TRobot" target="_blank">
                        <div className="corner"></div>
                        <div className="box-title">TRobot</div>
                        <div className="box-description">
                            <span>Mini application fun d'encodage de mots</span>
                            <p>Vous avez toujours rêvé d'écrire votre prénom en binaire, morse, runique ou Vigénère? TRobot le fait pour vous!</p>
                            <p className="my-2"><i>PHP 8, JS Vanilla, HTML, CSS</i></p>
                            <span className="fs-6">Cliquez pour découvrir</span>
                        </div>
                    </a>
                </div>

                <div className="project col-8 col-lg-7 col-xl-5" id="bakery">
                    <a className="text-decoration-none project-link" href="https://www.mariondev.fr/bakery" title="Lien vers le projet Bakery" target="_blank">
                        <div className="corner"></div>
                        <div className="box-title">Bakery</div>
                        <div className="box-description">
                            <span>Site vitrine fictif</span>
                            <p>Site vitrine d'une boulangerie fictive. Le but ? Apprendre React !</p>
                            <p className="my-2"><i>React, JS, CSS</i></p>
                            <span className="fs-6">Cliquez pour découvrir</span>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    )
}

export default Portfolio